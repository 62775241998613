.checkoutBtn {
    font-weight: 600;
    background-color: rgba(0, 91, 165, 1);
    color: white;
    min-height: 40px;
    border: none;
}
.checkoutBtn:hover {
    color: white !important;
    background-color: rgb(2, 111, 201);
}
.modalScroll {
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 46px;
}

/* issueModal */
.issueModal {
    min-height: 60dvh;
    max-height: 60dvh;
    overflow-y: auto;
    width: calc(100%);
    padding-right: 10px;
}
.issueModal_header {
    display: flex;
    justify-content: space-between;
    margin-block: 12px;
}
.addIssueModal {
    /* padding-block: 30px 20px; */
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 560px;
}
.profileModalWrapper {
    width: clamp(16rem, 80vw, 70rem) !important;
    min-height: 660px;
}