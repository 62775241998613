.container {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    /* align-items: center; */
    gap: 12px;
    /* height: calc(100%); */
    /* max-height: 1020px; */
    width: clamp(16rem, 96%, 100rem);
    margin-inline: auto;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.table {
    width: 100%;
}
.sumarizeSection {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
    height: auto;
}
.sumarizeItem {
    display: flex;
    flex-direction: column; 
    gap: 6px;
    background-color: white;
    border: 1px solid #d9d9d97a;
    border-radius: 8px;
    padding: 14px;
    min-width: 160px;
}
.sumarizeItem.clickable {
    cursor: pointer;
}
.sumarizeItem.clickable:hover {
    background-color: rgba(255, 255, 255, 0.532);
}
.sumarizeItem.active{
    border: 1px solid #c937377a;
}