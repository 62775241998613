
.LeftMenu {
  background-color: white;
  padding: 0 0 10px;
  width: 100%;
}
.sider {
  background-color: white !important;
  overflow-y: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.collapseBtn {
  position: absolute;
  top: 5px;
  left: 3px;
  transform: translate3d(5px, 5px, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 7;
}
@media only screen and (max-width: 1000px) {
  .sider {
    height: stretch;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    position: absolute !important;
    left: 0;
    z-index: 10;
  }
}