.historyContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100%);
  max-height: 1020px;
  /* width: clamp(16rem, 96%, 110rem); */
  /* margin-inline: auto; */
}

.table {
  width: 100%;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6rem .5rem;
  width: 100%;
  flex-wrap: wrap;
}

.tableWrapper {
  width: 100%;
  box-sizing: border-box;
}