.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
}
.unitBlock {
    width: 20px;
    height: 20px;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
}
.unitSeperator {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-1px);
}