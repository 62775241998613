.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* html {
  overflow: hidden;
} */
/* * {
  border: 1px solid red;
} */
body {
  margin: 0;
  /* min-height: 100dvh;
  max-height: 100dvh;
  overflow: hidden; */
}
header .ant-skeleton-element {
  line-height: normal !important;
}
.noHover {
  pointer-events: none;
}
.customScroll::-webkit-scrollbar-track
{
	border-radius: 10px;
  /* background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
}

.customScroll::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
  /* background-color: #F5F5F5 !important; */
}

.customScroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  /* background-color: #3131313b !important;
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2); */
}
.customScroll:hover::-webkit-scrollbar-thumb
{
  background-color: #3131318e !important;
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}
.customScroll:hover::-webkit-scrollbar-thumb:hover
{
  background-color: #31313152 !important;
}
.issues-tabs .ant-tabs-nav-list::-webkit-scrollbar-track
{
	border-radius: 10px;
  /* background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
}

.issues-tabs .ant-tabs-nav-list::-webkit-scrollbar
{
	width: 0px;
  height: 0px;
  /* background-color: #F5F5F5 !important; */
}

.leftMenu .ant-menu-item,
.leftMenu .ant-menu-submenu-title {
  margin-inline: 0 !important;
  border-radius: 0;
  height: 48px !important;
  width: 100%;
}
.leftMenu .ant-menu-item-selected {
  background-color: rgb(245 245 245);
  font-weight: 700;
  position: relative;
}
.leftMenu .ant-menu-item-selected::after {
  content: '';
  height: 100%;
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0046D1;
}
.ant-menu-inline-collapsed-tooltip { display: none; }
.searchInput {
  justify-content: center;
}
.searchInput .ant-input-prefix {
  margin-inline-end: 0 !important;
  margin-inline-start: 1px;
}
.searchInput .ant-input {
  background-color: #36354500;
  opacity: 0;
}
.searchInput.ant-input-affix-wrapper-focused .ant-input{
  opacity: 1;
  width: 100%;
  margin-left: 10px;
}
.operation-searchForm .ant-form-item .ant-form-item-label > label {
  align-items: flex-start;
}
.booking-progress .ant-steps-item .ant-steps-item-container {
  display: flex;
}
.booking-progress .ant-steps-item:nth-child(1),
.booking-progress .ant-steps-item:nth-child(1) .ant-steps-item-container .ant-steps-item-content {
  flex: 30% !important;
}
.booking-progress .ant-steps-item:nth-child(2),
.booking-progress .ant-steps-item:nth-child(2) .ant-steps-item-container .ant-steps-item-content {
  flex: 40% !important;
}
.booking-progress .ant-steps-item:nth-child(3),
.booking-progress .ant-steps-item:nth-child(3) .ant-steps-item-container .ant-steps-item-content {
  flex: 30% !important;
}
.booking-progress .ant-steps-item:nth-child(2) .ant-steps-item-container {
  display: flex;
}
.booking-progress .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-description {
  max-width: 320px;
}
/* .booking-progress {
  gap: 50px;
}
.booking-progress .ant-steps-item-container:nth-child(1) .ant-steps-item-icon {
  margin-inline-end: 45px !important;
}
.booking-progress .ant-steps-item-container:nth-child(1) .ant-steps-item-tail {
  padding: 4px 30px !important;
  margin-inline-start: 90px !important;
  margin-top: 20px;
}
.booking-progress .ant-steps-item-container:nth-child(1) .ant-steps-item-content {
  width: unset;
  margin-top: 5px;
}
.booking-progress .ant-steps-item-content{
  font-weight: 700;
}
.booking-progress .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon,
.booking-progress .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-content >.ant-steps-item-title {
  color: #1677ff;
}
.booking-progress .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon,
.booking-progress .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-content >.ant-steps-item-title {
  color: #3dabff8c;
} */
.ticketQty input::-webkit-outer-spin-button,
.ticketQty input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ticketQty input{
  appearance: textfield;
  -moz-appearance: textfield;
}
.ticket-card_form .ant-form-item-label{
  padding: 0 !important;
}
.ticket-card_form .ant-form-item-label > label{
  color: rgba(45, 54, 72, 1) !important;
  line-height: 16px;
}
.noMargin_form-item .ant-form-item {
  margin-top: 0;
  margin-bottom: 5px !important;
  font-weight: 600 !important;
}
.journey-step .ant-steps-item-title {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
}
.journey-step {
  height: 600px;
}
.journey-step.ant-steps-vertical >.ant-steps-item .ant-steps-item-content {
  min-height: 55px;
}
.journey-step >.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: rgba(5, 5, 5, 0.26);
}
/* .journey-step.ant-steps .ant-steps-item-error .ant-steps-item-icon,
.journey-step.ant-steps .ant-steps-item-error .ant-steps-item-container:hover .ant-steps-item-icon {
  background-color: #fdd536;
  border-color: #fdd536 !important;
}
.journey-step.ant-steps .ant-steps-item-error .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon,
.journey-step.ant-steps .ant-steps-item-error .ant-steps-item-container:hover .ant-steps-item-icon .ant-steps-icon {
  color: #bb7d00 !important;
} */
.journey-step.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e6f4ff !important;
  border-color: #e6f4ff !important;
}
.journey-step.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
  color: #1677ff !important;
}
.ant-steps .ant-steps-item.ant-steps-item-error>.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  color: #00000073;
}
.ant-steps .ant-steps-item.ant-steps-item-finish>.ant-steps-item-container[role='button']:hover .ant-collapse .ant-steps-item-title {
  color: #000000e0;
}
.journey-step.ant-steps .ant-steps-item-title {
  padding-inline-end: 0;
}
.journey-step.ant-steps .ant-steps-item-title .ant-collapse-header{
  border: 1px solid #d6d5d5;
}
.journey-step.ant-steps .ant-steps-item-title .ant-collapse-content-active{
  border: 1px solid #d6d5d5;
  border-top: none;
}
.journey-step.ant-steps .ant-steps-item-title .ant-collapse-content-active>.ant-collapse-content-box {
  padding-bottom: 0;
  padding-top: 12px;
}
.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  width: 4px !important;
}

.nightAudit-journey-step.ant-steps .ant-steps-item-title {
  display: block;
  line-height: 24px;
}
.issues-tabs .ant-tabs-nav,
.issues-tabs .ant-tabs-nav-list {
  display: flex !important;
  width: 100% !important; 
}
.issues-tabs .ant-tabs-nav .ant-tabs-tab {
  flex: 1;
  justify-content: center;
}
.isDriverTabs {
  width: 100%;
}
.isDriverTabs .ant-tabs-nav,
.isDriverTabs .ant-tabs-nav-list {
  display: flex !important;
  width: 100% !important; 
}
.isDriverTabs .ant-tabs-nav .ant-tabs-tab {
  flex: 1;
  justify-content: center;
}
.payDetailModal .ant-modal-content {
  padding: 0 !important;
}
.payDetailModal .ant-modal-content .ant-modal-footer{
  padding: 0 24px 20px !important;
}

/* [1-12-2023] [Phuc Thinh] 
Custom scrollbar for table */
.customScrollTable .ant-table-content::-webkit-scrollbar-track,
.customScrollTable .ant-table-body::-webkit-scrollbar-track
{
	border-radius: 10px;
  /* background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
}

.customScrollTable .ant-table-content::-webkit-scrollbar,
.customScrollTable .ant-table-body::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
  /* background-color: #F5F5F5 !important; */
}

.customScrollTable .ant-table-content::-webkit-scrollbar-thumb,
.customScrollTable .ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  background-color: #3131313b !important;
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}

.strongBorder .ant-checkbox .ant-checkbox-inner {
  border: 1px solid #897d7d;
}

.ant-picker:not(.ant-picker-disabled):hover .ant-picker-suffix {
  color: #4096ff;
}
.ant-popover {
  position: fixed;
}
.rmdp-day.rmdp-today span {
  background-color: #7fdbff40;
  color: #000;
}
.PhoneInput {
  --PhoneInputCountrySelectArrow-marginLeft: 0.75em;
}
.PhoneInputCountry {
  padding: 0 5px;
}
.PhoneInputInput {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}
.PhoneInputInput:hover {
  border-color: #4096ff;
}
.PhoneInputInput:focus,
.PhoneInputInput:focus-within {
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}
.ant-form-item-has-error .PhoneInputInput:focus,
.ant-form-item-has-error .PhoneInputInput:focus-within {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
  outline: 0;
}
.ant-form-item-has-error .PhoneInputInput {
  border-color: #ff4d4f;
}
/* ticketCard Tabs */
.ticketCard_tabs {
  height: 100%;
}
.ticketCard_tabs .ant-tabs-content-holder {
  overflow-y: auto;
}
.ticketCard_tabs .ant-tabs-nav {
  margin-bottom: 8px;
}
.ticketCard_tabs .ant-tabs-nav,
.ticketCard_tabs .ant-tabs-nav-list {
  display: flex !important;
  width: 100% !important; 
}
.ticketCard_tabs .ant-tabs-nav .ant-tabs-tab {
  flex: 1;
  justify-content: center;
}
.ticketCard_tabs .ant-tabs-content-holder::-webkit-scrollbar-track
{
	border-radius: 10px;
  /* background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
}

.ticketCard_tabs .ant-tabs-content-holder::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
  /* background-color: #F5F5F5 !important; */
}

.ticketCard_tabs .ant-tabs-content-holder::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  /* background-color: #3131313b !important;
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2); */
}
.ticketCard_tabs .ant-tabs-content-holder:hover::-webkit-scrollbar-thumb
{
  background-color: #3131318e !important;
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}
.ticketCard_tabs .ant-tabs-content-holder:hover::-webkit-scrollbar-thumb:hover
{
  background-color: #31313152 !important;
}
/* .collectionFee-row .ant-table-cell{
  background-color: rgba(245, 34, 45, 0.2) !important;
} */
.collectionFee-row .ant-table-cell-row-hover:not(.ant-table-cell-fix-right){
  background-color: #f5222d0b !important;
}
:not(.collectionFee-row) .ant-table-cell-row-hover{
  background-color: #fafafa;
}
.ticketTable .ant-table-thead .ant-table-cell-fix-right-first {
  right: 7px !important;
}
.ant-modal-close {
  top: 6px !important;
  inset-inline-end: 6px !important;
}
.ant-form-vertical .ant-form-item {
  margin-bottom: 10px !important;
}
.ant-form-item .ant-form-item {
  margin-bottom: 0px !important;
}
.ant-form-item-label {
  padding-bottom: 5px !important;
  font-weight: 600;
}

.customRowColor .ant-table-tbody .ant-table-row:hover {
  backdrop-filter: blur(10px);
}
.customRowColor .ant-table-tbody .ant-table-cell-row-hover:not(.ant-table-cell-fix-right) {
  background-color: transparent !important;
}
.customRowColor .ant-table-tbody .editable-row:hover .editable-cell-value-wrap {
  border-color: #b3b3b3;
}
.customRowColor .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
  background-color: transparent !important;
}

.ticket-card_form .ant-collapse>.ant-collapse-item >.ant-collapse-header {
  padding: 6px 16px !important;
  font-weight: 600;
}