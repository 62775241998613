.mainContent {
    padding: 10px 20px;
    overflow-y: auto;
    height: 100%;
    max-width: 100%;
}
@media screen and (max-width: 520px) {
    .mainContent {
        padding-inline: .5rem;
    }
}