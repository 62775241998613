.container {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    width: clamp(16rem, 98%, 110rem);
    margin-inline: auto;
}
.spaceBetween {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.hangHoasContainer {
    max-height: 300px;
    padding-right: 5px;
    overflow-y: auto;
}
.hangHoaFormItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.hangHoaFormItem__wrapper {
    border-bottom: 1px solid #00000036;
}