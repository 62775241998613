.jobTourModalContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.tabContainer {
    border: 1px solid rgba(0, 0, 0, 0.281);
    border-radius: 4px;
    box-sizing: border-box;
    align-self: stretch;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 15px 1rem;
    gap: 10px;
    min-height: 62px;
    box-sizing: border-box;
}
.tabTitle {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.jobCardContainer {
    border: 1px solid rgba(0, 0, 0, 0.281);
    border-radius: 4px;
    padding: .5rem;
    transform: translate3d(0, 0, 0);
    position: relative;
}
.jobTourTab {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 3;
}
.jobTourWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.jobTourList {
    /* max-height: max(65vh, 400px); */
    height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px 10px 0;
} 
.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    padding: .5rem;
    border-top: 1px solid #d9d9d9;
}
.jobItemTab {
    display: flex;
    flex-direction: column;
    flex: 2;
} 
.jobItemList {
    /* max-height: max(65vh, 400px); */
    
    height: 349px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 1rem 10px;
} 