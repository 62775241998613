.container {
    display: flex;
    flex-direction: column;
    min-height: 300px;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 10px;
}
.header_title {
    font-size: 20px;
    font-weight: 700 !important;
}
.formItem_group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}
.formItem_group > *:nth-of-type(1) {
    flex: 2
}
.formItem_group > *:nth-of-type(2) {
    flex: 3
}
.formItem {
    margin-bottom: 6px;
}
.service_header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
}
@media screen and (max-width: 1145px){
    .container {
        display: flex;
        flex-direction: column;
        min-height: 500px;
    }
}