.container {
    width: 100%;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}
.containerHead {
    min-height: 70px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-bottom: 1px solid rgba(216, 220, 221, 1);
}
.titleWrapper, .bookingWrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
}
.performanceList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(142px, 100%), 1fr));
    /* grid-template-rows: repeat(auto-fit, minmax(150%, 1fr)); */
    /* grid-auto-flow: column; */
}
.performanceItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 25px 13px;
    position: relative;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
    /* border-top: 1px solid rgba(216, 220, 221, 1);
    border-right: 1px solid rgba(216, 220, 221, 1); */
    /* border-bottom: 1px solid rgba(216, 220, 221, 1);
    border-right: 1px solid rgba(216, 220, 221, 1); */
    cursor: pointer;
    min-height: 100px;
    transition: box-shadow ease-in-out 250ms;
    max-height: 100px;
    margin-block: auto;
}
/* .performanceItem:last-child {
    border-top: 1px solid rgba(216, 220, 221, 1);
    border-left: 1px solid rgba(216, 220, 221, 1);
    border-right: none;
} */
.performanceItem:hover {
    box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}
.performanceDif {
    font-size: 12px;
    color: var(--color);
}
.performanceItem::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: var(--color);
    bottom: 0;
    left: 0;
}
.bookingWrapper {
    padding: 16px;
    gap: 10px;
    justify-content: space-between;
}
.bookingItem {
    display: flex;
    justify-content: space-between;
}
.newBook {
    color: rgba(0, 79, 204, 1);
}
.checkoutBook {
    color: rgba(87, 29, 175, 1);
}
.confirmBook {
    color: rgba(0, 171, 101, 1);
}
.cancelBook {
    color: rgba(136, 136, 136, 1);
}