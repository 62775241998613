.formItemGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}
.profileFormContainer {
    display: flex;
    width: 100%;
    gap: 20px;
}
.avatarInput {
    color: transparent;
    position: relative;
    height: 100%;
    cursor: pointer;
    border: none;
    outline: none;
}
.avatarInput::-webkit-file-upload-button {
    visibility: hidden;
}
.avatarInput:active,
.avatarInput:focus {
    outline: 0;
    box-shadow: unset !important;
}
.avatarInput::before {
    content: '📷';
    position: absolute;
    display: inline-block;
    background: rgba(32, 31, 31, 0.842);
    color: white;
    border-radius: 3px;
    padding: 0px 8px 10px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    font-weight: 700;
    font-size: 15pt;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.avatarInput:hover::before {
    background: rgba(70, 66, 66, 0.74);
}
.uploadContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    height: fit-content;
}
.uploadWrapper {
    position: relative;
    height: fit-content;
    width: fit-content;
}
.uploadIconWrapper {
    --edge-size: 100;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #656565;
    padding: 12px;
    border-radius: 50%;
    transform: translate(calc(50% - 30px), calc(50% - 50px));
    cursor: pointer;
}
.uploadIconWrapper:hover {
    background-color: #656565ec;
}
@media screen and (max-width: 780px) {
    .profileFormContainer {
        flex-direction: column-reverse;
    }
}