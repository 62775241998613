/* filterBar */
.icons {
    display: flex;
    gap: 30px;
}
.iconContainer {
    display: flex;
    gap: 12px;
    cursor: pointer;
    align-items: center;
}
.iconContainer:hover .iconText {
    text-decoration: underline;
}
.formIcon {
    font-size: 18px;
    color: rgba(82, 196, 26, 1);
}
.iconText {
    color: rgba(24, 144, 255, 1);
}