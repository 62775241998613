.roomContainer {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    width: clamp(16rem, 96%, 100rem);
    margin-inline: auto;
} 
.table {
    width: 100%;
}
.btnContainer {
    display: flex;
    gap: 20px;
    width: calc(80%);
    justify-content: space-between;
    margin-inline: auto;
}
.tableHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: .6rem 1rem;
    width: 100%;
}
.formItemGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}